
import Avatar from "@/components/Avatars/Avatar.vue";
import { namespace } from "s-vuex-class";
import { Options, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixins/UserMixin";
import useAuth from "@/composable/useAuth";
import { useIonRouter } from "@ionic/vue";
import { IonMenuToggle } from "@ionic/vue";

const AuthModule = namespace("auth");

@Options({
  components: { Avatar, IonMenuToggle },
})
export default class AvatarAndLogout extends mixins(UserMixin) {
  public name = "AvatarAndLogout";
  public auth = useAuth();
  private ionRouter = useIonRouter();

  @Prop({ default: () => "div" })
  public avatarWrapper!: string;

  public signOut() {
    this.auth.signOut();
  }

  public onClickProfile() {
    this.ionRouter.navigate("/profile", "forward", "push");
  }
}
