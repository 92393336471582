
import { computed, defineComponent, onMounted } from "vue";
import { IonApp, IonMenu, IonHeader, IonToolbar, IonContent, IonMenuToggle, IonLabel, IonItem } from "@ionic/vue";
import UserService from "@/services/UserService";
import AvatarAndLogout from "@/components/Layout/Top/AvatarAndLogout.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { useStore } from "vuex";
import useHelper from "@/composable/useHelper";

export default defineComponent({
  name: "BasicApp",
  components: {
    AvatarAndLogout,
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonMenuToggle,
    IonLabel,
    IonItem,
  },
  setup() {
    const store = useStore();
    const { isCurrentRouteNotTheoryAndPreliminaryExam } = useHelper();

    const findAllStudentEducationWithTheory = async (options: ICrudOptions) => {
      await store.dispatch("student-education/withTheory/findAll", options);
    };

    const getLink = computed(() => {
      const getDrivingSchoolId = UserService.getDrivingSchoolId();
      if (!getDrivingSchoolId) return "/";
      return `/basic/full-register/${getDrivingSchoolId}`;
    });

    onMounted(() => {
      if (isCurrentRouteNotTheoryAndPreliminaryExam.value) {
        findAllStudentEducationWithTheory({
          resource: "/student-educations/with-theory/student/current",
          descriptionField: "",
        });
      }
    });

    return {
      getLink,
    };
  },
});
