import { Options, Vue } from "vue-property-decorator";
import UserService from "@/services/UserService";

@Options({})
export default class UserMixin extends Vue {
  protected get currentUser() {
    return UserService.getUser();
  }

  protected get userName(): string {
    return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
  }

  protected get userId(): number {
    return UserService.getUser().id;
  }
}
