import { computed, inject, nextTick } from "vue";
import { useRouter } from "vue-router";

export default () => {
  const router = useRouter();

  const isCurrentRouteNotTheoryAndPreliminaryExam = computed<boolean>(() => {
    const ignoreRoutesName = ["PreliminaryExam", "Theory"];
    return router.currentRoute.value?.name ? ignoreRoutesName.indexOf(router.currentRoute.value.name.toString()) === -1 : true;
  });

  const setScrollY = inject("setScrollY", (value: boolean) => null);

  const scrollToPoint = inject("scrollToPoint", (valueY: number) => null);

  const onOpenMultiselect = async (ref: string, scrollY: number) => {
    await nextTick(() => {
      scrollToPoint(scrollY);
      setTimeout(() => {
        setScrollY(false);
      }, 100);
    });
  };

  const onCloseMultiselect = () => {
    setScrollY(true);
  };

  return {
    isCurrentRouteNotTheoryAndPreliminaryExam,
    setScrollY,
    scrollToPoint,
    onOpenMultiselect,
    onCloseMultiselect,
  };
};
